.block-main-nav.contextual-links-region:hover {
  .contextual-links-trigger {
    display: block;
  }
}

.block-main-nav, .block-search {
  position: absolute;
  left: 0;
  width: 80%;
  overflow: hidden;
  z-index: 10;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.7s ease;
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }
}

.block-search {
  width: 100%;
  height: auto;
  background: $black;
  transform: translate3d(0, -100%, 0);
}

.block-main-nav {
  margin-top: 1px;
  height: calc(100vh - 50px);
  background: #383838;
  .admin-menu & {
    height: calc(100vh - 50px - 29px);
  }
  .block-content {
    position: relative;
    height: 100%;
  }
}

.menu-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  z-index: 9;
  background: rgba(0, 0, 0, .5);
  visibility: hidden;
  transition: visibility 0s linear .5s, opacity .5s ease;
  .overflow-hidden & {
    visibility: visible;
    transition-delay: 0s;
    opacity: 1;
  }
}

.btn-main-menu {
  font-size: 30px;
}

.menu__level {
  padding-left: 10px;
  padding-right: 10px;
  @include breakpoint($tablet-screen) {
    padding-left: 35px;
    padding-right: 35px;
  }
}

.ml-menu {
  @extend .container;
  width: 100%;
  height: 100%;
}

.menu--open {
  transform: translate3d(0, 0, 0);
}

.menu__label {
  display: table;
  margin: auto;
  padding: 2em 0;
  width: calc(100% - 20px);
  border-bottom: 1px solid $grey;
  @include breakpoint($tablet-screen) {
    padding: 3em 0;
    width: calc(100% - 70px);
  }
}

.menu__wrap__container {
  position: relative;
}

.menu__wrap {
  position: relative;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background: $black;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: $black;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $grey-light;
  }
}

.menu__level {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 3em;
  padding-bottom: 3em;
  visibility: hidden;
  overflow-x: hidden;
  // overflow-y: auto;
  width: 100%;
  height: 100%;
  margin: 0;
  list-style-type: none;
}

.menu__level--current {
  visibility: visible;
  // overflow-y: auto;
  height: auto;
}

.menu__item {
  display: block;
  width: 100%;
}

.menu__link {
  position: relative;
  display: block;
  padding: .5em 2em .5em 0;
  color: $white;
  font-size: em(16px);
  font-weight: bold;
  font-family: $font-set-1;
  letter-spacing: 2px;
  text-transform: uppercase;
  @include breakpoint($narrow-screen) {
    font-size: em(18px);
  }
}

.menu__link[data-submenu]::after {
  content: '\e904';
  font-family: 'feather';
  position: absolute;
  right: 0;
  padding: 0.25em;
  // color: #2a2b30;
}

// .menu__link:hover,
// .menu__link:focus,
// .menu__link[data-submenu]:hover::after,
// .menu__link[data-submenu]:focus::after {
//   color: #5c5edc;
// }

// .menu__link--current::before {
//   content: '\00B7';
//   font-size: 1.5em;
//   line-height: 0;
//   position: absolute;
//   top: 50%;
//   left: 0.5em;
//   height: 4px;
// }

[class^='animate-'],
[class*=' animate-'] {
  visibility: visible;
}

.animate-outToRight .menu__item {
  animation: outToRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@keyframes outToRight {
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate-outToLeft .menu__item {
  animation: outToLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@keyframes outToLeft {
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate-inFromLeft .menu__item {
  animation: inFromLeft 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@keyframes inFromLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate-inFromRight .menu__item {
  animation: inFromRight 0.6s both cubic-bezier(0.7, 0, 0.3, 1);
}

@keyframes inFromRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.menu__breadcrumbs, .menu__logo {
  display: table-cell;
  vertical-align: middle;
}

.menu__logo {
  text-align: right;
  img {
    width: 50px;
    @include breakpoint($narrow-screen) {
      width: 70px;
    }
  }
}

.menu__breadcrumbs {
  // position: absolute;
  width: 80%;
  padding-top: 1.5em;
  a {
    font-size: em(14px);
    line-height: 1;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: lighter;
    color: $white;
    &:last-child {
      color: $primary;
      pointer-events: none;
    }
  }
}

.menu__breadcrumbs a:hover,
.menu__breadcrumbs a:focus {
  // color: #8182e0;
}

.menu__breadcrumbs a:not(:last-child)::after {
  content: '•';
  font-family: 'feather';
  display: inline-block;
  padding: 0 0.5em;
  // color: #33353e;
}

// .menu__breadcrumbs a:not(:last-child):hover::after,
// .menu__breadcrumbs a:not(:last-child):focus::after {
//   color: #33353e;
// }

.menu__back {
  font-size: 1.05em;
  position: absolute;
  z-index: 100;
  top: 0;
  right: 2.25em;
  margin: 0;
  padding: 1.365em 0.65em 0 0;
  cursor: pointer;
  // color: #2a2b30;
  border: none;
  background: none;
}

.menu__back--hidden {
  pointer-events: none;
  opacity: 0;
}

.menu__back:hover,
.menu__back:focus {
  // color: #fff;
  // outline: none;
}
