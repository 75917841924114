/* wysiwyg/_wysiwyg-styles.scss */

.highlight-type {
  font-weight: bold;
  color: $primary;
}

.inline-image-left {
  float: left;
  margin: 0 1em 1em 0;
}

.inline-image-right {
  float: right;
  margin: 0 0 1em 1em;
}

.styleguide-header {
  border-bottom: 1px solid white;
  font-size: 1em;
  text-transform: uppercase;
  margin-top: 2em;
  font-weight: bold;
  letter-spacing: 1px;
  clear: both;
}

.headline-type {
  @extend h2;
  text-transform: none;
}

.small-type {
  font-size: em(12px);
}
