.node--basic-page--default .content {
  @include add-grid(12);
  .field-name-field-image {
    margin-bottom: 2em;
  }
  .field-name-field-date, .field-name-field-location {
    margin-bottom: 1em;
    color: $primary;
  }
  .field-name-field-file-attachments {
    margin-top: 2em;
    .file-icon {
      display: none;
    }
    .field-item a {
      position: relative;
      display: block;
      padding: .5em 0;
      color: $white;
      text-decoration: underline;
      border-bottom: 1px solid $primary;
      &:hover {
        color: $primary;
      }
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 4px;
        right: 0;
        width: 18px;
        height: 23px;

        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint($standard-screen) {
          top: 5px;
          width: 22px;
          height: 27px;
        }
      }
    }
  }
  .educational-icon {
    position: absolute;
    left: 0;
    width: 50px;
    height: 50px;
    @include breakpoint($tablet-screen) {
      width: 80px;
      height: 80px;
    }
  }
}

.file-application-pdf:after {
  background-image: url(/sites/all/themes/de_theme/dist/img/icon-pdf-white.png);
}

.file-application-zip:after {
  background-image: url(/sites/all/themes/de_theme/dist/img/icon-zip-white.png);
}

.node-content-image {
  @include add-grid(12);
  .field-name-field-image {
    position: relative;
    margin-bottom: 1em;
    @include breakpoint($tablet-screen) {
      margin-bottom: 3em;
      @include grid-span(8, 5);
    }
    &.image-full-width {
      @include grid-span(12, 1);
    }
  }
}

.node-content-left {
  margin-bottom: 2em;
  @include breakpoint($tablet-screen) {
    @include grid-span(8, 5);
  }
}

.node-content-right {
  position: relative;
  padding-bottom: 50px;
  margin-bottom: 1em;
  @include breakpoint($tablet-screen) {
    padding-bottom: 70px;
    margin-bottom: 2em;
    @include grid-span(3, 1);
  }
  @include stripes('blue');
  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
  }
}

.node-content {
  clear: both;
}

.content-menu-page {
  @include breakpoint($tablet-screen) {
    width: 48%;
  }
}
