.view-id-news_and_events {
  @include clearfix;
  @include add-grid(12);
  .view-filters {
    border: 1px solid $primary;
  }
  .views-row {
    border: 1px solid $white;
  }
  .views-fieldset {
    position: relative;
    padding: 2em 2em 6em;
    @include stripes('white');
    &:before {
      bottom: 5px;
      left: 5px;
      width: calc(100% - 10px);
      height: 50px;
    }
  }
  .views-field-field-image {
    img {
      width: 100%;
    }
  }
  .views-field-title a {
    color: $white;
  }
  .views-field-body {
    margin-bottom: 2em;
  }
  .views-field-view-node {
    margin-bottom: 1em;
  }
  .views-field-field-news-category {
    color: $primary;
    font-size: em(14px);
  }
  .views-exposed-widget {
    margin-bottom: 0;
    @include breakpoint($tablet-screen) {
      margin-bottom: 1em;
    }
  }
  .views-widget-filter-field_news_category_tid {
    display: inline-block;
    width: 70%;
    float: left;
    @include breakpoint($tablet-screen) {
      display: block;
      width: auto;
      float: none;
    }
  }
  .views-submit-button {
    margin: 0 0 1em;
    float: left;
    @include breakpoint($tablet-screen) {
      margin: auto;
    }
    input {
      color: $black;
      background: $white;
    }
  }
  .views-reset-button {
    float: right;
  }
  .views-submit-button, .views-reset-button {
    display: inline-block;
    width: 30%;
    @include breakpoint($tablet-screen) {
      width: 49%;
    }
    input {
      padding: 9px 0;
    }
  }
  .bef-checkboxes {
    display: block;
    width: 100%;
    float: left;
    @include breakpoint($narrow-screen) {
      display: flex;
      flex-flow: wrap;
    }
    input[type=checkbox] {
      float: left;
      width: 10%;
    }
    .option {
      display: inline-block;
      width: 90%;
      vertical-align: top;
    }
  }
  .form-type-bef-checkbox {
    flex: 0 0 100%;
    @include breakpoint($narrow-screen) {
      flex: 0 0 50%;
    }
    @include breakpoint($tablet-screen) {
      flex: 0 0 100%;
    }
  }
  .pager-container {
    border: 1px solid $primary;
  }
}
