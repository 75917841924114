/**
 * This class affects the views:
 * product_listing, resource_library, educational_events, news_and_events, careers_at_whites
 * The targeted views can be found on the template.php file function de_theme_preprocess_views_view().
 * If your theming a specific page override/create it's specific sass files instead.
 **/

.view--listing-default {
  @include clearfix;
  @include add-grid(12);
  .view-content {
    @include breakpoint($tablet-screen) {
      @include grid-span(8, 1);
    }
  }
  .views-row {
    margin-bottom: 2em;
  }
  .view-filters {
    padding: 1em;
    margin-bottom: 2em;
    color: $white;
    background: $black;
    input[type=text] {
      width: 100%;
      border-color: $white;
    }
    @include breakpoint($tablet-screen) {
      margin-bottom: 1em;
      padding: 2em;
    }
  }
  .views-exposed-widget {
    @include clearfix;
    margin-bottom: 1em;
  }
  .views-submit-button, .views-reset-button {
    margin-bottom: auto;
    input {
      width: 100%;
    }
  }
  .views-submit-button input {
    @include button('white', 'black');
  }
  .views-submit-button, .views-reset-button {
    display: inline-block;
    width: 49%;
    @include breakpoint($tablet-screen) {
      width: 48%;
    }
  }
  .views-reset-button {
    float: right;
    input {
      @include button-hollow('white');
    }
  }
  .views-field-view-node a {
    &:hover {
      color: $white;
    }
  }
}

.pager-container {
  background: $black;
  margin-bottom: 1em;
  margin-top: 1em;
  &.desktop-up {
    display: none;
    @include breakpoint($tablet-screen) {
      display: block;
    }
  }
  &.mobile-only {
    border: 1px solid $primary;
    @include breakpoint(max-width $tablet-screen) {
      display: table;
      width: 100%;
      .to-top-container {
        border-left: 1px solid $grey;
      }
    }
    @include breakpoint($tablet-screen) {
      display: none;
    }
  }
  .pager, .to-top-container {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    @include breakpoint($tablet-screen) {
      display: block;
      width: 100%;
    }
  }
  .pager {
    padding: 1em;
    margin: 0;
    @include breakpoint($tablet-screen) {
      border-bottom: 1px solid $grey;
    }
    a {
      color: $white;
      &:hover {
        color: $primary;
      }
    }
  }
  .pager-last, .pager-first {
    display: none;
  }
  .pager-current {
    color: $white;
    font-weight: bold;
    text-decoration: underline;
  }
  .to-top-container {
    padding: 1em 2em;
    a {
      color: $white;
      text-decoration: underline;
      float: right;
      &:hover {
        color: $primary;
      }
    }
  }
  a.view-all-link {
    float: left;
  }
  .pager-all {
    display: none;
  }
}

.sticky-filters {
  // top: 13px;
  @include breakpoint($tablet-screen) {
    position: sticky;
    z-index: 5;
    top: 70px;
    @include grid-span(4, 9);
  }
  .admin-menu & {
    @include breakpoint($standard-screen) {
      top: 100px;
    }
  }
}
