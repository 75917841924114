@font-face {
  font-family: 'social-icons';
  src:
    url('../fonts/social-icons/social-icons.ttf') format('truetype'),
    url('../fonts/social-icons/social-icons.woff') format('woff'),
    url('../fonts/social-icons/social-icons.svg#social-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'social-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-vimeo:before {
  content: "\eaa0";
}
.icon-close:before {
  content: "\e900";
}
.icon-menu:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e903";
}
.icon-chevron-down:before {
  content: "\e902";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-chevron-thin-down:before {
  content: "\e905";
}
.icon-linkedin:before {
  content: "\eac9";
}
