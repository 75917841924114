.homepage_popup_class {
    background: none;
    width: 90vw !important;
    padding: 0;
    left: 50% !important;
    top: 3rem !important;
    transform: translateX(-50%);
    border: none !important;
    @include breakpoint($tablet-screen) {
      width: auto !important;
      max-width: 500px !important;;
    }

    &:hover {
        border: none !important;
    }

    .ui-dialog-titlebar {
        position: absolute;
        z-index: 500;
        right: 0.1rem;
        top: 0.5rem;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        > span {
            display: none;
        }
        > button {
            margin: 0 !important;
            border-radius: 0;
        }
    }

    a{
        color: none;
    }

    #homepage_popup_content {
        padding: 0;
        border: none !important;
        &:hover {
            border: none !important;
        }
    }
}

.ui-widget-overlay {
    background-image: none;
}

.ui-resizable-handle {
    display: none !important;
}