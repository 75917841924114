// /* wysiwyg/_wysiwyg-overrides.scss */

.cke_editable {
  padding: 1em;
  font-size: initial;
}

.cke_combopanel {
  width: 210px;
}

.cke_editable a {
  color: $primary;
  text-decoration: underline;
}

