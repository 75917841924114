.section-breadcrumb {
  border-top: 1px solid $grey;
  @include breakpoint($narrow-screen) {
    padding: 0 13px;
  }
  @include breakpoint($tablet-screen) {
    padding: 0 35px;
  }
  @include breakpoint($standard-screen) {
    padding: 0 35px;
  }
}

.easy-breadcrumb {
  text-transform: uppercase;
  a, .easy-breadcrumb_segment-title {
    display: inline-block;
    padding: 0 .5em;
    @include breakpoint($narrow-screen) {
      padding: .8em .5em;
    }
  }
  .easy-breadcrumb_segment-title,
  span:last-child a,
  a:hover {
    color: $primary;
  }
}

.breadcrumb-back, .breadcrumb-links {
  @include breakpoint($narrow-screen) {
    display: table-cell;
  }
}

.easy-breadcrumb_segment,
.easy-breadcrumb_segment-separator {
  font-size: em(12px);
  color: $grey-light;
  text-decoration: none;
  letter-spacing: .1em;
}

.breadcrumb-links {
  padding: 13px;
  @include breakpoint($narrow-screen) {
    padding: inherit;
  }
}

.breadcrumb-back {
  .breadcrumb-back-arrow {
    margin-right: 1em;
    padding: 1em .9em 1em 1.3em;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid $grey;
    background: #3e3e3e;
    @include breakpoint($narrow-screen) {
      padding: .8em .9em .8em 1.3em;
      width: auto;
      text-align: left;
      border-left: 1px solid $grey;
      border-right: 1px solid $grey;
      border-bottom: none;
    }
    &:hover {
      color: $white;
      background: $primary;
    }
    .lnr-chevron-left {
      display: inline-block;
      margin-top: -3px;
      vertical-align: middle;
      font-size: 1.8em;
    }
  }
}

