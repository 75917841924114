.view-page-resource-library {
  .section-content {
    color: $black;
    background: $white;
  }
}

.view-id-resource_library.view-display-id-page,
.view-resource-library.view-display-id-block_1 {
  @include breakpoint($tablet-screen) {
    table {
      width: 100%;
      th {
        font-family: $font-set-1;
        font-weight: lighter;
      }
      th, td {
        padding: 0.9em 1.5em;
      }
      td {
        border-bottom: 1px solid #d0d0d0;
      }
      .views-row-last td {
        border-color: $black;
      }
      .even {
        background-color: #f4f4f4;
      }
    }
  }
  .view-content {
    overflow: auto;
  }
  td:first-child {
    @include breakpoint($tablet-screen) {
      width: 50%;
    }
    a {
      color: $black;
      text-decoration: underline;
    }
  }
  td:last-child {
    @include breakpoint($tablet-screen) {
      text-align: center;
    }
    .tablesaw-cell-content {
      color: $primary;
      font-weight: bold;
      font-size: em(12px);
      letter-spacing: 2px;
      text-transform: uppercase;
      vertical-align: middle;
      @include breakpoint(max-width $tablet-screen - 1) {
        padding: 13px 26px;
      }
      @include breakpoint(max-width $narrow-screen - 1) {
        padding: 13px;
      }
    }
  }
  .views-exposed-widgets {
    @include add-grid(12);
  }
  .views-widget-filter-combine, .views-widget-filter-field_department_target_id {
    @include breakpoint($narrow-screen $tablet-screen - 1) {
      @include float-span(4);
    }
  }
  .views-widget-filter-field_type_tid {
    @include breakpoint($narrow-screen $tablet-screen - 1) {
      @include float-span(4, 'last');
    }
  }
  input[type=text], select {
    width: 100%;
  }
  .pager-container.mobile-only {
    border: none;
  }
}

.view-id-resource_library.view-display-id-page {
  .views-exposed-form label {
    @include element-invisible;
  }
  .views-field-title-1 a {
    color: $black;
    &:hover {
      color: $primary;
    }
  }
}

.view-resource-library.view-display-id-block_1 {
  @include breakpoint($tablet-screen) {
    .view-content {
      width: 100%;
    }
    td:first-child {
      width: 75%;
    }
    td:last-child {
      text-align: center;
    }
  }
  .views-field-field-type a {
    color: $black;
    &:hover {
      color: $primary;
    }
  }
}

.cal-wrapper {
  display: inline-block;
  position: relative;

  .cal-cont {
    position: absolute;
    top:75px;
    right:0;
    z-index: 99;
  }

  .cal-icon {
    padding-top: 33px;
    margin-left: -9px;
    &:after {
      font-size: 2.4em;
    }

  }
}

