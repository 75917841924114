.view--history-of-wfw--page {
  .view-header {
    margin-bottom: 4em;
    @include breakpoint($tablet-screen) {
      width: 66%;
    }
    h2 {
      margin-bottom: 1.5em;
    }
  }
  .views-row {
    @include clearfix;
    position: relative;
    padding: 2.5em 0;
    border-top: 1px solid $primary;
    &:before {
      @include breakpoint($tablet-screen) {
        content: '';
        position: absolute;
        top: 3.5em;
        left: -4.45em;
        width: 25px;
        height: 25px;
        border: 2px solid $primary;
        border-radius: 100%;
        background: $black;
      }
      @include breakpoint($standard-screen) {
        left: -4.25em;
      }
    }
  }
  .view-content {
    @include add-grid(12);
    position: relative;
    @include breakpoint($tablet-screen) {
      margin-left: 7em;
    }
    &:before {
      @include breakpoint($tablet-screen) {
        content: '';
        position: absolute;
        top: 0;
        left: -3.5em;
        width: 1px;
        height: 100%;
        background: $white;
        top: 3.6em;
        height: calc(100% - 3.6em);
      }
    }
  }
  .views-field-field-image {
    position: relative;
    @include breakpoint($tablet-screen) {
      @include grid-span(6, 7);
    }
    @include breakpoint($standard-screen) {
      @include grid-span(6, 7);
    }
    &:before {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
  .views-fieldset {
    @include breakpoint($tablet-screen) {
      @include grid-span(4, 1);
    }
  }
  .views-field-title {
    @extend h2;
    margin-bottom: 5px;
    color: $black;
    font-size: em(70px);
    -webkit-font-smoothing: antialiased;
    @include breakpoint($tablet-screen) {
      margin-top: -25px;
    }
  }
  .views-row-odd {
    .views-field-title {
      -webkit-text-stroke: 1px $primary;
      text-shadow: $primary 0px 0px 1px, $primary 0px 0px 1px, $primary 0px 0px 1px, $primary 0px 0px 1px, $primary 0px 0px 1px, $primary 0px 0px 1px, $primary 0px 0px 1px;
    }
    .views-field-field-image {
      @include stripes('white');
      img {
        @include breakpoint($small-screen) {
          border-right: 10px solid $black;
        }
        @include breakpoint($tablet-screen) {
          border-right: none;
        }
        @include breakpoint($standard-screen) {
          border-right: 10px solid $black;
        }
      }
    }
  }
  .views-row-even {
    .views-field-title {
      -webkit-text-stroke: 1px white;
      text-shadow: white 0px 0px 1px, white 0px 0px 1px, white 0px 0px 1px, white 0px 0px 1px, white 0px 0px 1px, white 0px 0px 1px, white 0px 0px 1px;
    }
    .views-field-field-image {
      @include stripes('blue');
      text-align: right;
      img {
        @include breakpoint($small-screen) {
          border-left: 10px solid $black;
        }
        @include breakpoint($tablet-screen) {
          border-left: none;
        }
        @include breakpoint($standard-screen) {
          border-left: 10px solid $black;
        }
      }
    }
  }
  .views-field-body p:last-child {
    margin-bottom: 0;
  }
}
