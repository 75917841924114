.views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 0;
}

.views-exposed-form .views-exposed-widget {
  float: initial;
  padding: 0;
}

.views-exposed-widgets {
  margin-bottom: 0;
}

.ajax-progress-throbber {
  display: none;
}

// #cboxTitle {
//   top: 100%;
// }

// #cboxContent {
//   margin-bottom: 50px;
// }

#cboxOverlay {
  background: $black;
}

#cboxPrevious, #cboxNext, #cboxClose {
  margin-right: 1px;
}

.slicknav_menu {
  position: relative;
  z-index: 30;
}

.node-unpublished {
  border: 2px solid red;
  padding: 1em;
  background-color: transparent;
}

.form-required {
  color: $primary;
}
