.node-job-postings {
  .field-name-field-career-location {
    color: $primary;
    margin-bottom: 5px;
  }
  .field-name-field-date {
    .field-label, span {
      color: $grey-light;
      font-weight: normal;
    }
  }
}
