.view--careers-at-whites--page {
  @include clearfix;
  @include add-grid(12);
  .view-filters {
    border: 1px solid $primary;
  }
  .views-row {
    position: relative;
    padding: 2em 2em 4em;
    border: 1px solid $white;
    @include stripes('white');
    &:before {
      bottom: 5px;
      left: 5px;
      width: calc(100% - 10px);
      height: 50px;
    }
  }
  .views-field-title a {
    @extend h2;
    display: inline-block;
    color: $white;
  }
  .views-field-body {
    margin-bottom: 2em;
  }
  .views-field-view-node {
    margin-bottom: 1em;
  }
  .views-field-field-career-location {
    color: $primary;
  }
  .views-field-field-date {
    color: $grey-light;
    margin-bottom: 1em;
    .field-content {
      display: inline;
    }
  }
  .views-exposed-widget {
    margin-bottom: 0;
    select {
      width: 100%;
      margin-bottom: 1em;
    }
    label {
      @include element-invisible;
    }
  }
  .views-submit-button {
    float: left;
    @include breakpoint($tablet-screen) {
      margin: auto;
    }
    input {
      color: $black;
      background: $white;
    }
  }
  .views-submit-button, .views-reset-button {
    display: inline-block;
    width: 30%;
    float: left;
    margin-right: 10px;
    @include breakpoint($tablet-screen) {
      margin-right: auto;
      width: 48%;
    }
    input {
      padding: 9px 0;
    }
  }
  .views-reset-button {
    @include breakpoint($tablet-screen) {
      float: right;
    }
  }
  .pager-container {
    border: 1px solid $primary;
  }
}
