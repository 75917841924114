.table-responsive {
  overflow: auto;
}

.left-example, .inline-image-left {
  margin-right: 1em;
  margin-bottom: 1em;
  float: left;
}

.right-example, .inline-image-right {
  margin-bottom: 1em;
  margin-left: 1em;
  float: right;
}

.full-width-example {
  width: 100%;
}
