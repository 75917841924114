.node-type-news {
  .section-content {
    color: $black;
    background: $white;
    .tabs a {
      color: $black;
      border-color: $black;
      &:hover {
        color: $white;
        background: $black;
      }
    }
    .node {
      a {
        color: $black;
        text-decoration: underline;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
