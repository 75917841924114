.view--production-credits--block-1 {
  @include clearfix;
  @include add-grid(12);
  @include add-gutter(.25);
  .views-row {
    @include float-span(6);
    margin-bottom: 1.69492%;
    @include breakpoint($narrow-screen) {
      @include float-span(3);
    }
    &:nth-child(2n) {
      @include float-span(6, 'last');
      @include breakpoint($narrow-screen) {
        @include float-span(3);
      }
    }
    &:last-child {
      @include float-span(6, 'last');
      @include breakpoint($narrow-screen) {
        @include float-span(3, 'last');
      }
    }
  }
}

.view--production-credits--page {
  @include clearfix;
  @include add-grid(12);
  .view-header {
    margin: 3em 0;
    @include breakpoint($tablet-screen) {
      margin: 5em 0;
      @include grid-span(6, 7);
    }
  }
  .view-content {
    @include clearfix;
  }
  .views-row {
    @include float-span(4);
    margin-bottom: 1.69492%;
    @include breakpoint($tablet-screen) {
      @include float-span(2);
    }
    &:nth-child(3n) {
      @include float-span(4, 'last');
      @include breakpoint($tablet-screen) {
        @include float-span(2);
      }
    }
    @include breakpoint($tablet-screen) {
      &:nth-child(6n) {
        @include float-span(2, 'last');
      }
    }
  }
  .pager-container {
    border: 1px solid $primary;
    display: table;
    width: 100%;
    .pager, .to-top-container {
      display: table-cell;
      width: 50%;
    }
    .pager {
      border-bottom: initial;
    }
    .to-top-container {
      border-left: 1px solid $grey;
    }
  }
}

.movie-stripe {
  position: relative;
  @include stripes('white');
  &:before {
    width: 100%;
    padding: 71% 0;
  }
}


