html {

}

body {
  font-family: $font-set-1;
  line-height: 1.4;
  font-size: em(13px);
  @include breakpoint($standard-screen) {
    font-size: inherit;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1em;
  font-family: $font-set-2;
  font-weight: 500;
  clear: both;
  // letter-spacing: 2px;
  line-height: 1.4;
  -moz-font-feature-settings: "liga", "kern";
  -moz-font-feature-settings:"liga=1, kern=1";
  -ms-font-feature-settings:"liga", "kern";
  -o-font-feature-settings:"liga", "kern";
  -webkit-font-feature-settings:"liga", "kern";
  font-feature-settings: "liga", "kern";
}

.not-front h1 {
  margin-bottom: 0;
}

.front {
  h1, h2 {
    text-transform: uppercase;
  }
  h2 {
    font-size: 11px;
    @include breakpoint($tablet-screen) {
      font-size: 14px;
    }
    @include breakpoint($standard-screen) {
      font-size: 16px;
    }
  }
}

#page-title {
  letter-spacing: 1px;
  text-transform: uppercase;
}

h1 {
  font-size: 15px;
  @include breakpoint($tablet-screen) {
    font-size: 22px;
  }
  @include breakpoint($standard-screen) {
    font-size: 28px;
  }
}
h2 {
  font-size: 14px;
  @include breakpoint($standard-screen) {
    font-size: 16px;
  }
}
h3 {
  font-size: 14px;
}
h4 {
  font-size: 13px;
}
h5 {
  font-size: 12px;
}
h6 {
  font-size: 11px;
}

a, input, button {
  transition: color .3s ease, background-color .3s ease, border .3s ease;
}

.view-header a, .node a {
  color: $white;
  text-decoration: underline;
  &:hover {
    color: $primary;
  }
}

a {
  color: $primary;
  text-decoration: none;
  &:hover {
    color: $primary-light;
  }
}

p {
  margin-top: 0;
}

ul, ol {
  padding-left: 1em;
}

img {
  max-width: 100%;
  height: auto;
  // Fixes image bottom spacing
  vertical-align: bottom;
}

input, textarea {
  border: 1px solid $black;
  font-size: em(12px);
  letter-spacing: 1px;
}

button, input, optgroup, select, textarea {
  padding: 9px;
  // Vaud font has a messed up line height :(.
  padding-bottom: 7px;
  color: $black;
  font-size: em(12px);
  border-radius: 0;
  line-height: 1.1;
}

.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  padding: 9px 15px;
  padding-bottom: 7px;
  color: $white;
  background: $black;
  border: 1px solid $black;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  @include breakpoint($standard-screen) {
    padding: 9px 30px;
    padding-bottom: 7px;
  }
  &:hover {
    background: $primary;
    border-color: $primary;
  }
}

input[type=search] {
  box-sizing: border-box;
}

select {
  appearance: none;
  position: relative;
  border: 1px solid $white;
  background: $white url('../img/arrow-down.png') no-repeat;
  background-size: 15px;
  background-position:  right 10px center;
}

td, th {
  padding: .5em;
}

blockquote {
  padding: 0;
  margin: 1em 0;
  color: $primary;
  font-size: em(16px);
  font-weight: bold;
  font-family: $font-set-2;
  p:last-child {
    margin-bottom: 0;
  }
}

table {
  width: 100%;
  th {
    color: $white;
    font-family: $font-set-2;
    text-transform: uppercase;
    letter-spacing: 2px;
    background: $black;
  }
  th, td {
    padding: 1em 1.5em;
    border: 1px solid $black;
    vertical-align: middle;
    @include breakpoint($wide-screen) {
      padding: 1.25em 3em;
    }
  }
}

caption {
  margin: 1em 0 .5em;
  text-align: left;
}
