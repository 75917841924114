.node-educational-events {
  .node-content-right {
    padding-left: 5em;
    @include breakpoint($tablet-screen) {
      padding-left: 7em;
    }
  }
  .field-name-field-link a {
    margin-top: 2em;
    @extend .btn;
  }
}
