.block-bean-homepage-blocks {
  position: relative;
  border: 1px solid white;
  vertical-align: top;
  margin-bottom: 1em;
  @include breakpoint($wide-screen) {
    margin-bottom: 1.3em;
  }
  .field-name-field-body p {
    margin-bottom: 0;
  }
}

.homepage-blocks-row {
  @include add-grid(12);
}

.homepage-blocks-tablet-only {
  @include breakpoint($standard-screen) {
    display: none;
  }
}

.block-bean-educational-events,
.block-bean-quote-tool {
  @include breakpoint($narrow-screen $standard-screen) {
    min-height: 320px;
  }
}

.block-bean-quote-tool {
  @include breakpoint($narrow-screen) {
    @include float-span(6, 'last');
  }
}

.block-bean-educational-events {
  @include breakpoint($narrow-screen) {
    @include float-span(6);
  }
}

.block-bean-quote-tool,
.block-bean-educational-events {
  border-color: $primary;
}

.block-bean-camera-rentals .homepage-blocks-content-right,
.block-bean-news .homepage-blocks-content-right,
.block-bean-virtual-production .homepage-blocks-content-left,
.block-bean-specialty-equipment .homepage-blocks-content-right,
.block-bean-lighting-and-grip .homepage-blocks-content-left,
.block-bean-studios .homepage-blocks-content-right,
.block-bean-location-support .homepage-blocks-content-left {
  height: 300px;
  width: 100%;
  @include breakpoint($narrow-screen) {
    // height: auto;
    width: 60%;
  }
  .field-name-field-homepage-slot-image {
    height: 100%;
  }
}
.block-bean-virtual-production .homepage-blocks-content-right,
.block-bean-camera-rentals .homepage-blocks-content-left,
.block-bean-news .homepage-blocks-content-left,
.block-bean-specialty-equipment .homepage-blocks-content-left,
.block-bean-lighting-and-grip .homepage-blocks-content-right,
.block-bean-studios .homepage-blocks-content-left,
.block-bean-location-support .homepage-blocks-content-right {
  @include stripes('white');
  h2 {
    color: $primary;
  }
}

.block-bean-quote-tool,
.block-bean-educational-events {
  @include stripes('blue');
}
.block-bean-virtual-production .homepage-blocks-content-right,
.block-bean-camera-rentals .homepage-blocks-content-left,
.block-bean-news .homepage-blocks-content-left,
.block-bean-specialty-equipment .homepage-blocks-content-left,
.block-bean-quote-tool,
.block-bean-educational-events,
.block-bean-lighting-and-grip .homepage-blocks-content-right,
.block-bean-studios .homepage-blocks-content-left,
.block-bean-location-support .homepage-blocks-content-right,
.view--homepage-block-featured-equipment--block .views-fieldset {
  &:before {
    bottom: 5px;
    right: 5px;
    width: calc(100% - 10px);
    height: 40px;
    z-index: 1;
    @include breakpoint($standard-screen) {
      height: 50px;
    }
  }
}
.featured-wrapper {
  margin-top: 7%;
  margin-bottom: 2%;
  @include breakpoint($narrow-screen) {
    margin-top: 20%;
    margin-bottom: 7%;
  }
  .featured-label {
    font-size: .75rem;
    margin-bottom: 0;
    @include breakpoint($narrow-screen) {
      font-size: inherit;
    }
    text-transform: uppercase;
  }
}


.homepage-blocks-social-links {
  width: 100%;
  @include breakpoint($narrow-screen) {
    position: absolute;
    bottom: 0;
  }
  a {
    display: inline-block;
    margin-top: 3.1%;
    padding: 0.5em;
    width: 48.4%;
    color: $grey;
    font-size: 2em;
    text-align: center;
    text-decoration: none;
    border: 1px solid $primary;
    @include breakpoint($narrow-screen) {
      width: 47.5%;
      padding: 22px;
      margin-top: 5.1%;
    }
    @include breakpoint(651px) {
      padding: 27px;
    }
    @include breakpoint($tablet-screen) {
      padding: 25px;
    }
    @include breakpoint(884px) {
      padding: 29px;
    }
    @include breakpoint($standard-screen) {
      padding: 20px;
      font-size: 2.5em;
    }
    @include breakpoint(1166px) {
      padding: 25px;
    }
    @include breakpoint($wide-screen) {
      padding: 23px;
    }
    &:hover {
      color: $white;
      background: $primary;
    }
    &:nth-child(odd) {
      float: left;
    }
    &:nth-child(even) {
      float: right;
    }
  }
}

.bean-homepage-blocks {
  .content {
    display: table;
    width: 100%;
    img {
      width: 100%;
      max-width: inherit;
    }
  }
}

a.homepage-block-link {
  @extend .btn-arrow;
  color: $primary;
  &:hover {
    color: $white;
  }
  &:after {
    width: 40px;
    height: 40px;
    font-size: 19px;
    @include breakpoint($standard-screen) {
      padding-top: 3px;
      font-size: 21px;
    }
  }
}

.homepage-blocks-content-right, .homepage-blocks-content-left {
  @include breakpoint($narrow-screen) {
    display: table-cell;
    vertical-align: top;
  }
}

.homepage-blocks-padding {
  padding: 2em 2em 5em;
  @include breakpoint($standard-screen) {
    padding: 3em;
  }
  .block-bean-social-media--2 & {
    padding: 2em;
  }
}

.homepage-blocks-content-right,
.homepage-blocks-content-left,
.homepage-blocks-content-square {
  position: relative;
  @include breakpoint($standard-screen) {
    height: 400px;
  }
}

.homepage-block-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.homepage-blocks-tablet {
  display: none;
  @include breakpoint($narrow-screen) {
    display: table-cell;
  }
}

.homepage-blocks-mobile-only {
  @include breakpoint($narrow-screen) {
    display: none;
  }
}

.view--homepage-block-featured-equipment--block {
  @extend .block-bean-homepage-blocks;
  width: 100%;
  .views-row {
    display: table;
    width: 100%;
  }
  // Mobile images
  .image-container-mobile {
    position: relative;
    height: 315px;
    @include breakpoint($small-screen) {
      height: 420px;
    }
    @include breakpoint($narrow-screen) {
      display: none;
    }
  }
  // Desktop Images
  .image-container {
    position: relative;
    display: none;
    @include breakpoint($narrow-screen) {
      display: table-cell;
      width: 60%;
      height: 324px;
    }
    @include breakpoint($tablet-screen) {
      height: 432px;
    }
    @include breakpoint($standard-screen) {
      width: 66%;
      height: 544px;
    }
    @include breakpoint($wide-screen) {
      height: 632px;
    }
  }
  .homepage-block-image {
    top: 0;
    position: relative;
  }
  .views-fieldset {
    box-sizing: border-box;
    @extend .homepage-blocks-padding;
    padding-bottom: 5em;
    @include breakpoint($narrow-screen) {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 40%;
    }
    @include breakpoint($standard-screen) {
      width: 34%;
    }
    @include stripes('white');
  }
  .views-field-view-node a {
    @extend .homepage-block-link;
  }
  .views-field-nothing {
    margin-bottom: 1em;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $primary;
    @include breakpoint($tablet-screen) {
      font-size: 14px;
    }
  }
  .views-field-title {
    color: #fff;
  }
}

// Featured News Homepage Block
.view--news-and-events--block-featured-news {
  margin-bottom: 1em;
}

.view--news-and-events--block-featured-news .view-content {
  width: 100% !important;
  @include breakpoint($tablet-screen) {
    background-position: -1% 93.5%;
  }
  @include breakpoint($standard-screen) {
    margin-right: 0 !important;
    max-height: 402px;
    background-position: 10% 98.5%;
  }
}

.view--news-and-events--block-featured-news .view-content .views-row {
  height: 650px;
  overflow: hidden;
  margin-bottom: 0em;
  position: relative;
  @include breakpoint($small-screen) {
    height: 620px;
  }
  @include breakpoint($narrow-screen) {
    height: 326px;
  }
  @include breakpoint($standard-screen) {
    height: 402px;
  }
}

.view--news-and-events--block-featured-news .view-content .views-row:before {
  content: "";
  position: absolute;
  background: url(../img/bg-stripes-white.png) 0 0 repeat;
  bottom: 5px;
  right: 5px;
  width: calc(100% - 10px);
  height: 40px;
  z-index: -1;
  @include breakpoint($narrow-screen) {
    right: 0px;
    left: 0px;
    width: 38.75%;
  }
  @include breakpoint($standard-screen) {
    height: 50px;
    left: auto;
    right: 9px;
  }
}

.view--news-and-events--block-featured-news .view-content .views-row .views-field-title {
  padding: 2em 2em 0 2em;
  @include breakpoint($narrow-screen) {
    float: left;
    width: 40%;
    padding: 2em 2em 0 2em;
  }
}

.view--news-and-events--block-featured-news .view-content .views-row .views-field-title h2 {
  color: #3a86ff;
}

.view--news-and-events--block-featured-news .view-content .views-row .featured-news-button {
  padding: 1em 2em 0 2em;
  //margin-bottom: 6em;
  a:after {
    width: 40px;
    height: 40px;
    padding-top: 3px;
    font-size: 21px;
  }
  @include breakpoint($narrow-screen) {
    padding: 1em 2em 0 2em;
  }
  @include breakpoint($tablet-screen) {
    margin-bottom: 2em;
  }
}



.homepage-featured-news-image {
  width: 100%;
  height: 60%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  @include breakpoint($narrow-screen) {
    width: 60%;
    height: 100%;
    float: left;
  }
}
