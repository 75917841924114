.node-department-category,
.node-expendable-categories {
  @include add-grid(12);
  .field-name-body {
    h2 {
      font-size: 16px;
      @include breakpoint($tablet-screen) {
        font-size: 18px;
      }
    }
    margin-bottom: 2em;
    @include breakpoint($tablet-screen) {
      @include grid-span(8, 1);
    }
  }
}
