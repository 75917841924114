.block-white-noise {
  .view-header {
    @include breakpoint($tablet-screen) {
      width: 70%;
    }
  }
  .view-content {
    @include breakpoint($tablet-screen) {
      width: 70%;
    }
    h3 {
      @extend h2;
      margin-top: 3em;
      margin-bottom: 1.7em;
    }
  }
  .views-row-1 {
    border-top: 1px solid $primary;
  }
  .views-field-title a {
    position: relative;
    display: block;
    padding: .7em 0;
    color: $white;
    border-bottom: 1px solid $primary;
    &:after {
      content: "";
      position: absolute;
      top: 7px;
      right: 0;
      width: 21px;
      height: 26px;
      background-image: url('/sites/all/themes/de_theme/dist/img/icon-pdf-white.png');
      background-size: 17px;
      background-repeat: no-repeat;
      @include breakpoint($standard-screen) {
        top: 9px;
        background-size: 20px;
      }
    }
    &:hover {
      color: $primary;
    }
  }
}
