.page-search {
  .search-form {
    @include clearfix;
    margin-bottom: 3em;
    .form-type-searchfield label {
      display: block;
      margin-bottom: 1em;
      @extend h2;
    }
    .form-search {
      width: 68%;
      float: left;
      border: 1px solid $white;
      @include breakpoint($tablet-screen) {
        width: 78%;
      }
    }
    .form-submit {
      @include button-hollow('white');
      width: 30%;
      float: right;
      @include breakpoint($tablet-screen) {
        width: 20%;
      }
    }
  }
  #block-system-main {
    @include breakpoint($tablet-screen) {
      width: 70%;
    }
    ol {
      margin: 1.5em 0;
      padding: 0;
      list-style: none;
      @include breakpoint($tablet-screen) {
        margin: 3em 0;
      }
      .title a {
        color: $white;
        @extend .btn-arrow;
        &:hover {
          color: $white;
          &:after {
            color: $white;
          }
        }
        &:after {
          position: absolute;
          bottom: 1em;
          left: 0;
          color: $primary;
          transition: color .3s ease;
        }
      }
      li {
        position: relative;
        padding: 1em 0 6em;
        margin: 1em 0;
        border-bottom: 1px solid $grey;
        @include breakpoint($standard-screen) {
          padding-bottom: 5em;
        }
      }
      h3 {
        @extend h2;
        text-transform: none;
      }
    }
  }
}

.search-title {
  text-transform: uppercase;
  color: $primary;
}
