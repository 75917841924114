@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
.clearfix {
  @include clearfix;
}

.element-invisible {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
}

@mixin element-invisible() {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
}

// @include stripes('white');
@mixin stripes($color) {
  &:before {
    content: '';
    position: absolute;
    background: url(../img/bg-stripes-#{$color}.png) left top repeat;
  }
}

@mixin button($bgColor, $textColor) {
  display: inline-block;
  color: #{$textColor};
  font-size: em(12px);
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  background: #{$bgColor};
  border: 1px solid #{$bgColor};
  &:hover {
    color: #{$textColor};
    background: $primary;
    border-color: $primary;
  }
}

@mixin button-hollow($color) {
  @include button('white', 'black');
  color: #{$color};
  background: transparent;
  border: 1px solid #{$color};
  &:hover {
    color: $primary;
    background: transparent;
    border-color: $primary;
  }
}

$browser-context: 16px;
// px to em converter - font-size: em(32px) or em(32);
@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1em;
}

.overflow-hidden {
  overflow: hidden;
  position: relative;
  height: 100%;
}
