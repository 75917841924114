.tabs {
  margin: 0 0 1em;
  padding: 0;
  li {
    display: inline-block;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    list-style: none;
  }
  a {
    @extend input;
    @include button-hollow('white');
    // font-size: .75em;
  }
}
