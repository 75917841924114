.toolbelt-title {
  padding-top: 1.5em;
  margin-bottom: 1.5em;
  text-transform: uppercase;
  border-top: 1px solid $black;
}

.view-page-whites-toolbelt, .view-page-whites-toolbelt-landing {
  .section-content {
    color: $black;
    background: $white;
    a {
      color: $black;
      &:hover {
        color: $primary;
      }
    }
    .tabs a {
      @include button-hollow('black');
    }
  }
}

.view-id-whites_toolbelt_landing {
  @include clearfix;
  @include add-grid(12);
  .view-header {
    @include breakpoint($tablet-screen) {
      @include grid-span(6, 1);
    }
  }
  .view-filters {
    @include breakpoint($tablet-screen) {
      @include grid-span(4, 9);
    }
  }
  .view-content {
    @include clearfix;
    padding-top: 2em;
    clear: both;
    @include breakpoint($tablet-screen) {
      padding-top: 4em;
    }
  }
  .views-row {
    position: relative;
    padding-top: 1.5em;
    margin-top: 1em;
    margin-bottom: 2em;
    border-top: 1px solid $black;
    @include breakpoint($tablet-screen) {
      display: inline-block;
      width: 48%;
    }
  }
  .views-row-even {
    @include breakpoint($tablet-screen) {
      float: right;
    }
  }
  .views-row-odd {
    @include breakpoint($tablet-screen) {
      float: left;
    }
  }
  .views-field-name {
    text-transform: uppercase;
  }
  .views-field-nothing a {
    &:hover {
      color: $black;
    }
  }
}

.toolbelt-icon:after {
  content: "";
  position: absolute;
  top: 30px;
  right: 1em;
  background-size: contain;
  background-repeat: no-repeat;
  @include breakpoint($tablet-screen) {
    top: 40px;
  }
}
.toolbelt-cable-and-distribution:after {
  top: 50px;
  right: 2.2em;
  width: 40px;
  height: 80px;
  background-image: url(/sites/all/themes/de_theme/dist/img/icon-toolbelt-cable.png);
  @include breakpoint($tablet-screen) {
    top: 50px;
    right: 2.6em;
    width: 50px;
  }
}
.toolbelt-camera:after {
  top: 60px;
  width: 80px;
  height: 80px;
  background-image: url(/sites/all/themes/de_theme/dist/img/icon-toolbelt-camera.png);
  @include breakpoint($tablet-screen) {
    top: 74px;
    width: 100px;
  }
}
.toolbelt-temporary-power-sources:after {
  top: 50px;
  right: 1.7em;
  width: 80px;
  height: 80px;
  background-image: url(/sites/all/themes/de_theme/dist/img/icon-toolbelt-power.png);
  @include breakpoint($tablet-screen) {
    top: 55px;
    width: 100px;
  }
}
.toolbelt-lighting:after {
  width: 60px;
  height: 80px;
  right: 1.7em;
  background-image: url(/sites/all/themes/de_theme/dist/img/icon-toolbelt-lighting.png);
  @include breakpoint($tablet-screen) {
    top: 51px;
    right: 1em;
    width: 80px;
  }
}
.toolbelt-grip:after {
  width: 60px;
  height: 80px;
  right: 1.9em;
  background-image: url(/sites/all/themes/de_theme/dist/img/icon-toolbelt-grip.png);
  @include breakpoint($tablet-screen) {
    top: 50px;
    width: 80px;
  }
}
.toolbelt-dimming-and-control-systems:after {
  top: 50px;
  width: 75px;
  height: 80px;
  right: 1em;
  background-image: url(/sites/all/themes/de_theme/dist/img/icon-toolbelt-dimming.png);
  @include breakpoint($tablet-screen) {
    top: 60px;
    width: 90px;
    right: 1.3em;
  }
}
.toolbelt-expendables:after {
  top: 40px;
  width: 70px;
  height: 80px;
  right: 1.1em;
  background-image: url(/sites/all/themes/de_theme/dist/img/icon-toolbelt-expendables.png);
  @include breakpoint($tablet-screen) {
    top: 50px;
    width: 100px;
  }
}
.toolbelt-sources:after {
  top: 50px;
  width: 54px;
  height: 60px;
  background-image: url(/sites/all/themes/de_theme/dist/img/icon-toolbelt-sources.png);
  @include breakpoint($tablet-screen) {
    right: 2.2em;
    top: 58px;
    height: 80px;
  }
}

.view-id-whites_toolbelt {
  @include clearfix;
  @include add-grid(12);
  .view-content a {
    position: relative;
    display: block;
    padding: .7em 0;
    color: $black;
    border-bottom: 1px solid $black;
    &:hover {
      color: $primary;
    }
  }
  .views-field-title a {
    &:after {
      content: "";
      position: absolute;
      top: 6px;
      right: 0;
      width: 21px;
      height: 26px;
      background-image: url('/sites/all/themes/de_theme/dist/img/icon-pdf.png');
      background-size: 20px;
      background-repeat: no-repeat;
      @include breakpoint($standard-screen) {
        width: 27px;
        height: 34px;
        background-size: 26px;
      }
    }
  }
  // .view-filters {
  //   @include breakpoint($tablet-screen) {
  //     @include grid-span(4, 9);
  //   }
  // }
  .toolbox-container {
    @include breakpoint($tablet-screen) {
      @include grid-span(8, 1);
    }
  }
}

// Search box
.view-id-whites_toolbelt_landing, .view-id-whites_toolbelt {
  .view-filters {
    padding: 2em;
    background: $black;
  }
  .views-exposed-form label {
    @include element-invisible;
  }
  .form-text {
    margin-bottom: 1em;
    width: 100%;
    border: 1px solid $white;
  }
  .views-submit-button, .views-reset-button {
    display: inline-block;
    width: 49%;
    @include breakpoint($tablet-screen) {
      width: 48%;
    }
    input {
      width: 100%;
    }
  }
  .views-submit-button {
    float: left;
    input {
      margin-right: 1em;
      color: $black;
      background: $white;
      &:hover {
        background: $primary;
      }
    }
  }
  .views-reset-button {
    float: right;
    input {
      @include button-hollow('white');
    }
  }
}

.toolbelt-subgroup a {
  font-weight: bold;
  &:after {
    content: "\e902";
    position: absolute;
    right: 0;
    top: 3px;
    display: inline-block;
    padding-top: 1px;
    width: 30px;
    height: 30px;
    font-size: 18px;
    font-family: 'feather';
    text-align: center;
    border: 2px solid;
    border-radius: 50%;
    @include breakpoint($standard-screen) {
      top: 7px;
    }
  }
}
