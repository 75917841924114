.view--educational-events--page {
  .view-header {
    margin-bottom: 3em;
    @include breakpoint($tablet-screen) {
      width: 66%;
    }
  }
  .view-filters {
    border: 1px solid $primary;
  }
  .views-row {
    position: relative;
    padding: 2em 2em 6em;
    border: 1px solid $white;
    @include stripes('white');
    &:before {
      bottom: 5px;
      left: 5px;
      width: calc(100% - 10px);
      height: 50px;
    }
  }
  .views-field-field-educational-event-type, .views-fieldset {
    display: table-cell;
    vertical-align: top;
  }
  .views-fieldset {
    padding-left: 1em;
  }
  .views-field-field-image {
    img {
      width: 100%;
    }
  }
  .views-field-title a {
    color: $white;
  }
  .views-field-field-location {
    color: $primary;
    margin-bottom: 1.5em;
  }
  .views-field-body {
    margin-bottom: 2em;
  }
  .views-field-view-node {
    margin-bottom: 1em;
  }
  .views-exposed-widget {
    margin-bottom: 0;
    @include breakpoint($tablet-screen) {
      margin-bottom: 1em;
    }
  }
  .views-widget-filter-field_educational_event_type_tid {
    display: inline-block;
    width: 70%;
    float: left;
    @include breakpoint($tablet-screen) {
      display: block;
      width: auto;
      float: none;
    }
  }
  .views-submit-button {
    margin: 0 0 1em;
    float: left;
    @include breakpoint($tablet-screen) {
      margin: auto;
    }
    input {
      color: $black;
      background: $white;
    }
  }
  .views-reset-button {
    float: right;
  }
  .views-submit-button, .views-reset-button {
    display: inline-block;
    width: 30%;
    @include breakpoint($tablet-screen) {
      width: 49%;
    }
    input {
      padding: 9px 0;
    }
  }
  .bef-checkboxes {
    display: block;
    width: 100%;
    float: left;
    @include breakpoint($narrow-screen) {
      display: flex;
      flex-flow: wrap;
    }
    input[type=checkbox] {
      float: left;
      width: 10%;
      margin-top: 3px;
    }
    .option {
      display: inline-block;
      width: 89%;
      margin-left: 1%;
      vertical-align: top;
    }
  }
  .form-type-bef-checkbox {
    flex: 0 0 100%;
    @include breakpoint($narrow-screen) {
      flex: 0 0 50%;
    }
    @include breakpoint($tablet-screen) {
      flex: 0 0 100%;
    }
    @include breakpoint($standard-screen) {
      flex: 0 0 50%;
    }
  }
  .pager-container {
    border: 1px solid $primary;
  }
}

.educational-icon {
  width: 80px;
  height: 80px;
  border: 1px solid white;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
}

.educational-icon--events {
  background-image: url(/sites/all/themes/de_theme/dist/img/icons-educational-events-white.png);
  background-size: 70%;
}
.educational-icon--webinar {
  background-image: url(/sites/all/themes/de_theme/dist/img/icons-educational-webinar-white.png);
}
.educational-icon--workshop {
  background-image: url(/sites/all/themes/de_theme/dist/img/icons-educational-workshop-white.png);
}
.educational-icon--other {
  background-image: url(/sites/all/themes/de_theme/dist/img/icons-educational-other-white.png);
}
